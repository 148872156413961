
.MyAddress{
    padding: 134px 0px;
}

.MyAddress .brdr-btm-light {
    width: 100%;
    border-bottom: 1px solid #8d8c8c6e;
}

.MyAddress .crd-btn-active {
    width: 100%;
    display: flex;
    max-width: 650px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2.5rem 1.5rem;
    border: 2px solid black;
    position: relative;
}

.MyAddress .crd-btn-nonactive {
    width: 100%;
    display: flex;
    max-width: 650px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2.5rem 1.5rem;
    border: 1px solid #707070;
    position: relative;
}

.MyAddress .default_opt {
    display: absolute;
    position: absolute;
    bottom: 10%;
    right: 8%;
    border: 1px solid;
    padding: 0.5rem 1.5rem;
    border-radius: 43px;
}

.MyAddress .radio_portion {
    position: relative;
    border-right: 2px solid #7070704a;
    padding-right: 1rem;
    margin-right: 1rem;
    /* align-self: center; */
}

.MyAddress .my-personal {
    align-self: center;
    margin-top: 3px;
}

.MyAddress .flx-radio-address {
    display: flex;
    width: 100%;
}

.MyAddress .top_portion {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.MyAddress .text-portion {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.MyAddress .tick-color {
    color: #0EB702;
}

.MyAddress input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: #ffffff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid black;
}

.MyAddress input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: #000000;
    content: '';
    display: inline-block;
    visibility: visible;
    box-shadow: inset 0px 0px 0px 2px #fff;
    border: 1px solid black;
}

.MyAddress input[type='radio'] {
    cursor: pointer;
}

.checkboxOverride input[type="checkbox"]:checked+label:after {
    opacity: 1;
}
