/* imput radio css external starts*/


input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: #ffffff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid black;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: #000000;
    content: '';
    display: inline-block;
    visibility: visible;
    box-shadow: inset 0px 0px 0px 2px #fff;
    border: 1px solid black;
}

input[type='radio'] {
    cursor: pointer;
}

/* imput radio css external ends*/

/* input checkbox starts here */

.checkboxOverride input[type="checkbox"]:checked+label:after {
    opacity: 1;
}

.checkboxOverride label {
    background: none repeat scroll 0 0 white;
    border: 1px solid #DDDDDD;
    cursor: pointer;
    height: 20px;
    left: 10px;
    position: absolute;
    top: 2px;
    width: 20px;
    border-radius: 5px;
    border: 1px solid #00000085;
}

.checkboxOverride label:after {
    border-style: none none solid solid;
    content: "";
    height: 6px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 12px;
}

/* input checkbox ends here */


.checkout_page {
    padding: 80px 0px;
}
.checkout_page .main_heading {
    font-size: 43px;
    font-family: 'RobotoSlab-Bold';
}

.checkout_page .checkout_p {
    font-family: 'RobotoSlab-Light';
    font-size: 16px;
    color: #1D212D;
}

.checkout_page .checkout_subheading {
    color: #1D212D;
    font-size: 22px;
    font-family: 'RobotoSlab-Medium';
}

.checkout_page .brdr-btm-light{
    width: 100%;
    border-bottom: 1px solid #8d8c8c6e;
}

.checkout_page .crd-btn-active {
    width: 100%;
    display: flex;
    max-width: 650px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2.5rem 1.5rem;
    border: 2px solid black;
    position: relative;
}

.checkout_page .crd-btn-nonactive {
    width: 100%;
    display: flex;
    max-width: 650px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2.5rem 1.5rem;
    border: 1px solid #707070;
    position: relative;
}

.checkout_page .default_opt {
    display: absolute;
    position: absolute;
    bottom: 10%;
    right: 8%;
    border: 1px solid;
    padding: 0.5rem 1.5rem;
    border-radius: 43px;
}

.checkout_page .radio_portion {
    position: relative;
    border-right: 2px solid #7070704a;
    padding-right: 1rem;
    margin-right: 1rem;
    /* align-self: center; */
}

.checkout_page .my-personal{
    align-self: center;
    margin-top: 3px;
}

.checkout_page .flx-radio-address {
    display: flex;
    width: 100%;
}

.checkout_page .top_portion {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.checkout_page .text-portion {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.checkout_page .tick-color {
    color: #0EB702;
}

.checkout_page .select_field {
    font-size: 15px;
    font-family: 'RobotoSlab-Medium';
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid #707070;
    border-radius: 0;
    color: #B4B4B4;
}

.checkout_page .inpt_fld {
    border-left: 0;
    font-size: 15px;
    font-family: 'RobotoSlab-Medium';
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid #707070;
    border-radius: 0;
    color: #B4B4B4;
}

.checkout_page .checkout_label {
    font-family: 'RobotoSlab-Medium';
    font-size: 15px;
    color: #1D212D;
}

.checkout_page .clr-light-save {
    color: #B4B4B4;
}

.checkout_page .use_save_head{
    font-size: 20px;
    font-family: 'RobotoSlab-Medium';
    color: #1D212D;
}
.checkout_page .lh-01{
    line-height: 1;
}

.checkout_page .default_opt_nopos {
    bottom: 10%;
    right: 8%;
    border: 1px solid;
    padding: 0.5rem 1.5rem;
    border-radius: 43px;
    margin-bottom: 0;
    align-self: center;
}

.checkout_page .d-flex_visa_opt{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.checkout_page .flx-radio-address_visa{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.checkout_page .crd-btn-active-payment {
    width: 100%;
    display: flex;
    max-width: 650px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem 1.5rem;
    border: 2px solid black;
    position: relative;
}

.checkout_page .crd-btn-non-active-payment {
    width: 100%;
    display: flex;
    max-width: 650px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem 1.5rem;
    border: 1px solid #707070;
    position: relative;
}

.checkout_page .radio_portion-payment {
    position: relative;
    border-right: 2px solid #7070704a;
    padding-right: 1rem;
    margin-right: 1rem;
    align-self: center;
}

.checkout_page .stripePay-Btn{
    background: transparent;
    color: black;
    width: 100%;
    padding: 1rem;
    border-radius: 100px;
    border: 2px solid;
    font-size: 20px;
    font-family:"RobotoSlab-SemiBold" ;
}

.checkout_page .btn-apply{
    border: 2px solid;
    border-radius: 50px;
    padding: 0.5rem 2.5rem;
    font-size: 20px;
    margin-top: 2.6rem;
}

.checkout_page .crd-details{
    border: 2px solid #B4B4B4;
    padding: 2rem;
}

.checkout_page .product_details{
    font-size: 20px;
    font-family:"RobotoSlab-Bold" ;
}

.checkout_page .prod_id{
    font-size: 13px ;
    font-family:"RobotoSlab-Medium" ;
}

.checkout_page .prod_id{
    font-size: 13px ;
    font-family:"RobotoSlab-Medium" ;
}

.checkout_page .prod_name{
    font-size: 15px ;
    font-family:"RobotoSlab-Medium" ;
}

.checkout_page .card_bill{
    font-size: 17px;
    font-family:"RobotoSlab-SemiBold" ;
}

.checkout_page .card_payment{
    font-size: 17px;
    font-family:"RobotoSlab-Medium" ;
}

.checkout_page .card_total{
    font-size: 20px;
    font-family:"RobotoSlab-SemiBold" ;
}

.checkout_page .card_total_bill{
    font-size: 17px;
    font-family:"RobotoSlab-SemiBold" ;
}

.checkout_page .confirm_order_text{
    font-size: 17px;
    color: #707070;
    font-family:"RobotoSlab-Medium" ;
}

.checkout_page .cnfrm_btn{
    font-size: 20px;
    font-family:"RobotoSlab-SemiBold" ;
    background: transparent;
    color: black;
    width: 60%;
    padding: 1rem;
    border-radius: 100px;
    border: 2px solid;
}

.checkout_page .align-self-center{
    align-self: center;
}

.checkout_page .cursor_pointer{
    cursor: pointer;
}

.checkout_page .confirmed_order{
    height: 100vh;
    width: 100vw;
    background-color: #020914;
    position: fixed;
}