.productDetails{
    padding: 80px 0px;
}

.productDetails .main-title{
    font-size: 60px;
    font-family: 'RobotoSlab-Regular';
    color: #1D212D;
}

.productDetails .main-para {
    font-size: 12px;
    font-family: 'RobotoSlab-Regular';
    color: #1D212D;
    margin: 0px;
}

.productDetails .cate-text {
    color: #1D212D;
    font-size: 16px;
    font-family: 'RobotoSlab-Regular';
}

.productDetails .btn-details{
    border: 1px solid #1A1E38;
    border-radius: 22px;
    padding: 7px 22px;
}
.productDetails .rating-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
}

.productDetails .review-count {
    font-size: 16px;
    color: #1D212D;
    font-family: 'RobotoSlab-Regular';
}

.productDetails select {
    border: unset;
    color: #70707061;
    font-size: 22px;
    font-family: 'RobotoSlab-Regular';
    padding: 10px 0px;
    width: 100%;
}

.productDetails .select-p{
    font-size: 16px;
    color: #1D212D;
    font-family: 'RobotoSlab-Regular';
    margin: 0px;
}

.productDetails .select-Container {
    border-bottom: 2px solid #bfbfbf;
    margin-top: 25px;
    margin-bottom: 25px;
}

.productDetails .detail-actionContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.productDetails .qty-container {
    background: #e3e3e3;
    padding: 5px 18px;
}

.productDetails .slick-custom{
    margin-top: -22px;
}

/* post Review  */
.productDetails .post-review .add-review {
    color: #1D212D;
    font-size: 23px;
    font-family: 'RobotoSlab-Regular';
}

.productDetails .post-review .label {
    font-size: 15px;
    color: #1D212D;
    font-family: 'RobotoSlab-Regular';
    margin: 0px;
}

.productDetails .post-review input {
    padding: 10px 4px;
    border: unset;
    font-size: 20px;
    border-bottom: 1px solid #7070706b;
    color: #7070706b;
    font-family: 'RobotoSlab-Regular';
    margin-top: 2px;
    margin-bottom: 16px;
    width: 100%;
}

.productDetails .post-review ::placeholder {
    color: #1d212d30;;
}

.productDetails .input-container{
    margin-bottom: 22px;
    margin-top: 22px;
}

.productDetails .post-review .post-btn{
    padding: 8px 38px;
    border-radius: 26px;
    border: 1px solid #1a1e38;
}
.productDetails .product-review {
    padding: 75px 0px;
}
.productDetails .All-Reviews .profile {
    height: 70px;
    width: 70px;
    overflow: hidden;
}

.productDetails .All-Reviews .date {
    font-size: 16px;
    color: #1D212D;
    font-family: 'RobotoSlab-Regular';
    font-weight: 400;
    margin: 0;
    margin-bottom: 10px;
}

.productDetails .All-Reviews .details{
    color: #848484;
    font-size: 12px;
    font-family: 'RobotoSlab-Regular';
    margin: 0px;
    margin-bottom: 10px;
}

.productDetails .product-review .review-title{
    color: #1B213B;
    font-size: 30px;
    font-family: 'RobotoSlab-Regular';
}

.productDetails .product-review .review-hr-line{
    width: 100%;
    color: #707070;
}

.productDetails .qty-arrow {
    height: auto;
    width: auto;
    overflow: hidden;
    padding: 0px 8px;
}