.features_page .headings {
    font-size: 48px;
    font-family: 'RobotoSlab-Medium';
    color: #1D212D;
    margin-bottom: 0rem;
}

.features_page .border-btm-gry {
    border-bottom: 3px solid #1D212D;
    width: 25%;
}

.features_page .text_all {
    font-size: 16px;
    font-family: 'RobotoSlab-Regular';
    color: #1D212D;
}

.features_page .learn_more_btn {
    font-size: 18px;
    font-family: 'RobotoSlab-Semibold';
    border: 2px solid #1A1E38;
    border-radius: 30px;
    padding: 0.5rem 2.5rem;
    font-size: 18px;
}

.features_page .bg_images_content {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin: auto;
}