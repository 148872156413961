.about .banner-content{
    position: absolute;
    top: 290px;
    left: 40px;
}

.about .banner-titleAbout{
    font-family: 'Valorant';
    font-size: 40px;
    color: #fff;
    margin: 0;
}

.about .banner-contentAbout {
    position: absolute;
    top: 192px;
    left: 177px;
}

.about-top{
    padding: 100px 100px;
}

.about-top .title{
    font-size: 48px;
    font-family: 'RobotoSlab-Regular';
    font-weight: 400;
    color: #1D212D;
}

.about-top .hr-line{
    width: 134px;
    height: 2px;
    color: #1D212D !important;
    border-radius: 12px;
    background-color: #1D212D !important;
    border-color: #1D212D;
}

.about-top .main-para{
    font-size: 12px;
    font-family: 'RobotoSlab-Regular';
    color: #1D212D;
}

.about-top .learn-btn{
    border: 1px solid #000;
    border-radius: 50px;
    padding: 7px 22px;
    margin-top: 10px;
}

.mission-main{
    padding: 26px 80px;
}

.mission-main .mission-card{
    border: 1px solid #E4EBE5;
    padding: 24px 24px;
}

.mission-main .mission-card .card-para{
    color: #1D212D;
    font-size: 11px;
    font-family: 'RobotoSlab-Regular';
    margin: 0;
}

.mission-main .mission-card .card-title{
    color: #1D212D;
    font-size: 31px;
    font-family: 'RobotoSlab-Regular';
    margin: 0;
}

.mission-main .mission-card  .hr-line{
    color: #1D212D;
    width: 118px;
    height: 2px;
    border-radius: 12px;
    margin-top: 8px;
}

.team {
    padding: 60px 60px;
}

.team .main-title{
    color: #1D212D;
    font-size: 44px;
    font-family: 'RobotoSlab-Regular';
    text-align: center;
    font-weight: 400;
    margin: 0;
}

.team .hr-line{
    width: 126px;
    height: 3px;
    text-align: center;
  
}
.team .team-info{
    padding: 30px 0px;
}

