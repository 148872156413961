.AddAddress{
    padding: 134px 0px;
}

.AddAddress .main_heading {
    font-size: 43px;
    font-family: 'RobotoSlab-Bold';
}

.AddAddress .checkout_p {
    font-family: 'RobotoSlab-Light';
    font-size: 16px;
    color: #1D212D;
}

.AddAddress .checkout_subheading {
    color: #1D212D;
    font-size: 22px;
    font-family: 'RobotoSlab-Medium';
}

.AddAddress .brdr-btm-light {
    width: 100%;
    border-bottom: 1px solid #8d8c8c6e;
}

.AddAddress .select_field {
    font-size: 15px;
    font-family: 'RobotoSlab-Medium';
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid #707070;
    border-radius: 0;
    color: #B4B4B4;
}

.AddAddress .checkout_label {
    font-family: 'RobotoSlab-Medium';
    font-size: 15px;
    color: #1D212D;
}

.AddAddress .inpt_fld {
    border-left: 0;
    font-size: 15px;
    font-family: 'RobotoSlab-Medium';
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid #707070;
    border-radius: 0;
    color: #B4B4B4;
}