.reviews_page .main_heading {
    font-size: 52px;
    font-family: "RobotoSlab-SemiBold";
}

.reviews_page .heading_reviews {
    font-size: 19px;
    font-family: "RobotoSlab-Bold";
    margin-bottom: 2rem;
}

.reviews_page .desc_reviews {
    font-size: 16px;
    font-family: "Poppins-Regular";
    color: #616161;
    margin-left: 3rem;
    border-left: 5px solid #EEEEEE;
    padding-left: 1.5rem;
}

.reviews_page .icon_rating {
    font-size: 19px;
}

.reviews_page .single_review {
    border-bottom: 1px solid #D9D6D6;
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
}

.reviews_page .add_review {
    font-size: 30px;
    font-family: "RobotoSlab-Bold";
}

.reviews_page .review_inpt {
    font-size: 15px;
    padding: 0.7rem;
    font-family: "RobotoSlab-Medium";
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid #707070;
    width: 50%;
    padding-left: 0;
}

.reviews_page .review_label{
    font-size: 15px;
    font-family: "RobotoSlab-Medium";
    color: #262626b3;
}

.reviews_page .textarea_reviews{
    width: 50%;
    resize: none;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #707070;
}
.bt-submit-reviews , .bt-submit-reviews:focus ,.bt-submit-reviews:active{
    border: 2px solid #1A1E38;
    border-radius: 100px;
    padding: 0.5rem 3.5rem ;
    font-family: "RobotoSlab-SemiBold";
    font-size: 20px;
    margin-right: 1rem;
}

.cancel_reviews{
    font-family: "RobotoSlab-SemiBold";
    font-size: 20px;
    color: #999999;
    cursor: pointer;
}
.cancel_reviews:hover{
    text-decoration: underline;
}