.image-width {
   width:  100%;
}

.flex-style{
   display: flex;
   justify-content: center;
   align-items: center;
}

hr.hr-line {
   background-color: #1D212D !important;
   opacity: 60;
}

.url-head{
   background-color: #F5F5F5;
   padding: 20px 20px;
}

.url-head .title{
   color: #1B253D;
    font-size: 28px;
    text-align: center;
    font-family: 'RobotoSlab-Regular';
    margin: 0;
}

.change-password .save-btn{
   border: 1px solid #212529;
   padding: 8px 36px;
   border-radius: 20px;
   margin-top: 27px;
}

.change-password .user-fields-change{
   padding: 94px 0px;
}

.change-password input{
   width: 100%;
   padding: 10px 16px;
   border: unset;
   border-bottom: 1px solid #888888;
}