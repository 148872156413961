.shop{
    padding: 80px 0px ;
}


.shop .shop-left{
    height: auto;
    width: 100%;
    /* background-color: rgb(98, 142, 180); */
    padding: 20px 20px;
}

.shop .cate-title{
    font-size: 22px;
    font-family: 'RobotoSlab-Regular';
    color: #1D212D;
}

.shop .cate-sub{
    font-size: 16px;
    font-family: 'RobotoSlab-Regular';
    color: #1D212D;
    padding: 7px 0px;
}

.shop ul li{
    list-style: none;
}

.shop .size-title{
    font-size: 19px;
    font-family: 'RobotoSlab-Regular';
    color: #1D212D;
}

.shop .size-sub{
    font-size: 16px;
    font-family: 'RobotoSlab-Regular';
    color: #1D212D;
    padding: 7px 0px;
}

.shop .color-title{
    font-size: 19px;
    font-family: 'RobotoSlab-Regular';
    color: #1D212D;
}

.shop .color-sub{
    font-size: 16px;
    font-family: 'RobotoSlab-Regular';
    color: #1D212D;
    padding: 7px 0px;
    margin-left: 14px;
}

.shop .color-box{
    height: 16px;
    width: 16px;
}
.shop .color-dv{
    display: flex;
    align-items: center;

}

.shop-right .product-card .title{
    font-size: 22px;
    text-align: center;
    color: #1D212D;
    font-family: 'RobotoSlab-Regular';
}

.shop-right .product-card .price{
    font-size: 23px;
    text-align: center;
    color: #1d212d7d;
    font-family: 'RobotoSlab-Regular';
}

.shop-right .product-card .card {
    border:  unset !important;
}
.shop-right .product-card .card:hover{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.shop-right .product-card {
    margin-top: 10px;
    margin-bottom: 10px;
}

.shop-right .product-card .product-content {
    margin-top: 16px;
    margin-bottom: 10px;
}

.shop-right select{
    border: unset;
}

.shop-right .result-container{
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 34px;
}

.shop-right .result-container .result-text{
    font-size: 16px;
    font-family: 'RobotoSlab-Regular';
    color: #1D212D;
    margin: 0;
}

.shop-right .break {
    margin: -2px 8px 0px 8px;
}

.shop .price-footer{
    display: flex;
    justify-content: space-between;;
    align-items: center;
}

.shop .price-footer p {
    font-size: 12px;
    font-family: 'RobotoSlab-Regular';
}

.shop .price-footerInner{
    display: flex;
    justify-content: center;
    align-items: center;
}