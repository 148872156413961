.bookmark{
    margin-top: 55px;
    margin-bottom: 85px;
}
.bookmark .title{
    color: #ffff;
    font-size: 31px;
    font-family: Roboto-Bold;
}
.bookmark .desc{
    color: #ffff;
    font-size: 16px;
    font-family: 'Roboto-Regular';
    text-align: center;
    margin-top: 20px;
}
.bookmark .tableDv .button-group a.btn.grey {
    background: #EEEEEE;
    color: #000;
}
.bookmark .heading {
    padding-bottom: 40px;
}
.bookmark table {
    width: 100%;
}
.bookmark .tableDv {
    background: #fff;
    /* margin: 0 70px; */
    padding: 35px 30px;
    border-radius: 0px;
}
.bookmark .products .productDetail .imgBox {
    overflow: hidden;
    width: 65px;
    height: 65px;    
    border-radius: 50%;
    /* margin: 16px; */
}
.bookmark .products .productDetail .product-detail {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.bookmark .products .productDetail .product-detail .img-box {
    min-width: 140px;
}
.bookmark table tr.topbar {
    border-bottom: 1px solid #C5C5C5;
    height: 40px;
}
.bookmark .products {
    height: 130px;
    border-bottom: 1px solid #C5C5C5;
}
.bookmark .tableDv th.productDetail {
    width: 50%;
}
.bookmark .tableDv .Proprice {
    width: 20%;
}
.bookmark .tableDv .addcart {
    width: 30%;
}
.bookmark .tableDv .addcart .ActionBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bookmark table tr.topbar .productDetail {
    text-align: center;
}
.bookmark .tableDv .addcart .ActionBtn .cartBtn p {
    margin-bottom: 0;
    font-size: 17px;
}
.bookmark .tableDv .addcart .ActionBtn .cartBtn p a {
    color: #e7adc4;
}
.bookmark .tableDv .addcart .ActionBtn .cartBtn p i {
    font-size: 15px;
}
.bookmark .tableDv .addcart .ActionBtn .deleteBtn i {
    color: #e7adc4;
    cursor: pointer;
}
.bookmark .tableDv .addcart .ActionBtn .deleteBtn i:hover {
    color: #57102c;
    cursor: pointer;
}
.bookmark .tableDv .button-group {
    margin-top: 30px;
}
.bookmark .tableDv .button-group a.btn {
    background: #000000;
    box-shadow: none;
    padding: 10px 20px;
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    border-radius: 0px;
    margin-right: 16px;
}
.bookmark .tableDv .button-group a.btn:nth-child(1):hover{
    background: #262262 !important;
}
.bookmark .tableDv .button-group a.btn.grey {
    background: #EEEEEE;
    color: #000;
}
.bookmark .button-group a.btn {
    background: #000000;
    box-shadow: none;
    padding: 10px 20px;
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    border-radius: 0px;
    margin-right: 16px;
}
.bookmark .button-group a.btn.grey {
    background: #EEEEEE;
    color: #000;
    padding: 10px 60px;
}

.bookmark .button-group{
    margin-top: 20px;
}
.bookmark .addToCart-btn{
    background: #000000;
    box-shadow: none;
    padding: 10px 20px;
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    border-radius: 0px;
}
.bookmark .topbar{
    font-size: 16px;
    font-family: 'RobotoSlab-Bold';
    color: #4a4a4a;
}
.bookmark .ProductName {
    font-size: 13px;
    font-family: 'RobotoSlab-Regular';
    color: #000000;
    width: 80px;
    font-weight: 400;
}
.bookmark .total {
    font-size: 15px;
    font-family: Poppins-SemiBold;
    color: #1F2C35;
}
.bookmark .deleteBtn i {
    color: #EF5D61;
}

.bookmark .myOrder-delete {
    width: 8%;
}

.bookmark .ViewDetails{
    background: #000000;
    box-shadow: none;
    padding: 10px 20px;
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    border-radius: 0px;
}
