.Home{
    /* background-image: url('../images//banner/banner1.png');
    background-size: cover;
    height: 100vh;
    width: 100%; */
}

.banner .banner-content{
    position: absolute;
    top: 290px;
    left: 40px;
}
.banner .banner-content .banner-inner{
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner .banner-title{
    font-family: 'Valorant';
    font-size: 95px;
    color: #fff;
    margin: 0;
}
.banner .banner-subTitle{
    font-size: 33px;
    color: #fff;
    font-family: 'RobotoSlab-Regular';
    margin-left: 28px;
}
.banner .banner-rotateText{
    font-size: 22px;
    font-family: 'Valorant';
    color: #fff;
    transform: rotate(90deg);
    margin-bottom: 26px;
}

.banner .hr-line{
    width: 68%;
    background-color: #ffff;
    margin-left: 31px;
    margin-top: 4px;
    margin-bottom: 28px;
}

.Features {
    padding: 100px 0px;
}
.Features .main-title{
    font-size: 34px;
    font-family: Valorant;
    color: #1B2638;
    text-align: center;
}

.Features .main-para{
    font-size: 14px;
    font-family:  RobotoSlab-Regular;
    color: #1B2638;
    text-align: center;  
    margin-bottom: 75px;
}

.Features .title{
    font-size: 33px;
    font-family: RobotoSlab-Regular;
    color: #1D212D;
}
.Features .para{
    font-size: 14px;
    font-family: RobotoSlab-Regular;
    color: #1D212D;
}

.Features .feat-dv1{
    overflow: hidden;
}

.Features .learn{
    border: 1px solid #1d212d;
    border-radius: 20px;
    padding: 8px 20px;
    font-family: 'RobotoSlab-Regular';
    margin-bottom: 10px;
    margin-top: 10px;
}

.our-products{
    background-color: black;
}

.NewArrivals {
    /* padding: 100px 0px; */
    padding-bottom: 100px;
    padding-top: 185px;
}
.NewArrivals .main-title{
    color: #1A2131;
    text-align: center;
    font-size: 36px;
    font-family: 'Valorant';
}
.NewArrivals .main-para{
    color: #1A2131;
    text-align: center;
    font-size: 13px;
    font-family: 'Valorant';
}
.NewArrivals .inner-container{
    margin-top: 60px;
    margin-bottom: 60px;
}

.NewArrivals .block-title{
    font-size: 44px;
    color: #141531;
    font-family: Poppins-Bold;
    margin-bottom: 20px;
}

.NewArrivals .btn-container {
    margin-top: 30px;
    margin-bottom: 30px;
}
.NewArrivals .block-para{
    font-size: 14px;
    color: #141531;
    font-family: Poppins-Regular;
}

.NewArrivals .viewAll{
    border: 1px solid #212529;
    border-radius: 20px;
    padding: 8px 18px;
}

.work-container {
    background-image: url('../images//ht-bg1.png');
    background-size: cover;
    height: 108vh;
    width: 100%;
}

.work-container .main-title{
    font-size: 36px;
    color: #fff;
    text-align: center;
}

.work-container .main-para{
    font-size: 13px;
    margin: 0px;
    text-align: center;
    color: #fff;
    padding-top: 72px;
}

.work-container .video-container{
    margin-top: 65px;
}

.News{
    padding: 70px 70px;
}
.News .main-title{
    font-size: 36px;
    text-align: center;
    color: #1A2131;
    font-family: 'Valorant';
}
.News .main-para{
    font-size: 13px;
    text-align: center;
    color: #1A2131;
    font-family: 'RobotoSlab-Regular';
}
.News .news-letter{
    margin-top: 70px;
    margin-bottom: 70px;
}

.News .news-letter{
    display: flex;
    justify-content: center;
    align-items: center;
}
.News .news-img{
    width: 100%;
}
.News .news-card {
    background-color: #F5F5F5;
    padding: 21px;
}

.News .cardTitle{
    font-size: 24px;
    font-family: 'Poppins-Regular';
    font-weight: 600;
    color: #0A0C11;
    margin-top: 10px;
}
.News .cardPara{
    font-size: 15px;
    font-family: 'Poppins-Regular';
    color: #0A0C11;
}