.contact{
    padding: 100px 0px;
}

.contact input {
    width: 100%;
    padding: 10px 20px;
    border: unset;
    border-bottom: 1px solid #aeb7c1;
    margin-top: 26px;
}

.contact textarea {
    width: 100%;
    padding: 10px 20px;
    border: unset;
    border-bottom: 1px solid #aeb7c1;
    margin-top: 38px;
    height: 170px;
}

.contact .submit {
    padding: 10px 28px;
    border: 1px solid #0e0d0d;
    border-radius: 36px;
}

.contact .main-title{
    font-size: 40px;
    color: #222A41;
    font-family: 'RobotoSlab-Regular';
}

 .contact_component{
    padding: 5rem 0;
    background: #F5F5F5;
}

 .contact_component .contact_img{
    height: 80px;
}

 .contact_component .contact_box{
    display: flex;
    align-items: center;
}

 .contact_component .contact_heading{
    font-size: 22px;
    font-family: 'Poppins-Semibold';
    margin-bottom: 0;
    margin-left: 1rem;
}

 .contact_component .contact_text{
    font-size: 18px;
    font-family: 'RobotoSlab-Regular';
    margin-bottom: 0;
    margin-left: 1rem;
    color: #7D7D7D;
}