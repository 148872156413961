
figure {
    padding: 0;
}
/* Table Css Start Here */
.productCart{
    margin-bottom: 70px;
    margin-top: 70px;
}
.productCart .tableDv .button-group a.btn.grey {
    background: #EEEEEE;
    color: #000;
}
.productCart .title{
    color: #1d212d;
    font-family: 'RobotoSlab-Regular';
    font-weight: 600;
    font-size: 31px;
}
.productCart .desc{
    color: #1d212d94;
    font-size: 16px;
    font-family: 'RobotoSlab-Regular';
    text-align: center;
    margin-top: 20px;
}
.productCart .name {
    font-size: 13px;
    color: #1F2C35;
    font-family: Poppins-Regular;
    font-weight: 600;
}
.productCart .priceText {
    font-size: 15px;
    font-family: Poppins-SemiBold;
    color: #000000;
    text-align: center;
}
.productCart .deleteBtn {
    color: #EF5D61;
}
.productCart .increment-Decrement {
    background-color: #cfcfcf;
}
.productCart .heading {
    padding-bottom: 40px;
}
.productCart table {
    width: 100%;
}
.productCart .tableDv {
    background: #fff;
    margin: 0 10px;
    padding: 35px 30px;
    width: 100%;
    border: 1px solid #1d212d75;
    border-radius: 20px;
}
.productCart .products .productDetail .product-detail {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.productCart .products .productDetail .product-detail .img-box {
    min-width: 140px;
}
.productCart table tr.topbar {
    border-bottom: 1px solid #C5C5C5;
    height: 40px;
}
.productCart .products {
    height: 130px;
    border-bottom: 1px solid #C5C5C5;
}
.productCart .tableDv th.productDetail {
    width: 40%;
    font-size: 16px;
    font-family: Poppins-Bold;
    color: #4A4A4A;
}
.productCart .tableDv .Proprice {
    width: 20%;
    font-size: 16px;
    font-family: Poppins-Bold;
    color: #4A4A4A;
    text-align: center;
}
.productCart .tableDv .qty {
    width: 15%;
    font-size: 16px;
    font-family: Poppins-Bold;
    color: #4A4A4A;
    text-align: center;
}
.productCart .tableDv .qty input {
    border-radius: 0;
    padding: 0 10px;
    width: 80px;
    text-align: center;
    height: 35px;
    outline: none;
}
.productCart .tableDv .addcart {
    width: 25%;
    font-size: 16px;
    font-family: Poppins-Bold;
    color: #4A4A4A;
    text-align: center;
}
.productCart .tableDv .addcart .ActionBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.productCart table tr.topbar .productDetail {
    text-align: center;
}
.productCart .tableDv .addcart .ActionBtn .cartBtn p {
        margin-bottom: 0;
        font-size: 15px;
        font-family: 'Poppins-SemiBold';
        color: #000000;
}
.productCart .tableDv .addcart .ActionBtn .cartBtn p a {
    color: #e7adc4;
}
.productCart .tableDv .addcart .ActionBtn .cartBtn p i {
    font-size: 15px;
}
.productCart .tableDv .addcart .ActionBtn .deleteBtn i {
    color: #e7adc4;
    cursor: pointer;
}
.productCart .tableDv .addcart .ActionBtn .deleteBtn i:hover {
    color: #3b3135;
    cursor: pointer;
}
.productCart .button-group {
    margin-top: 30px;
}
.productCart .button-group a.btn {
    box-shadow: none;
    padding: 10px 32px;
    font-size: 17px;
    font-weight: 500;
    color: #020202;
    border-radius: 0px;
    margin-right: 16px;
    border: 1px solid #000;
    border-radius: 28px;
}
.productCart .button-group a.btn.grey {
    background: transparent; 
    color: #1b213b;
    padding: 10px 60px;
    font-family: 'RobotoSlab-Regular';
    border: unset;
}
.productCart .discountCode {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.productCart .discountCode .head {
    width: 250px;
}
.productCart .discountCode .head p {
    margin-bottom: 0;
    color: #525252;
    font-size: 18px;
    font-weight: 400;
    font-family: Poppins-Regular;
}
.productCart .discountCode .applyCoupon {
    display: flex;
    width: 100%;
}
.productCart .discountCode .applyCoupon input {
    width: 100%;
    background: transparent;
    border-radius: 0;
    color: #A8A8A8;
    outline: none;
    border: unset;
    /* border: 1px solid #c1c1c1; */
    border-radius: 20px;
    padding: 7px 16px;
    border: 1px solid #0e0e0e;
}
.productCart .discountCode .applyCoupon button {
    font-size: 12px;
    color: #fff;
    background: #0D0D0D;
    border: 0;
    padding: 8px 24px;
    border-radius: 20px;
    margin-left: 10px;
}
.productCart .discountCode .applyCoupon input::placeholder {
    color: #A8A8A8;
    font-size: 10px;
}
.productCart .productSummary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #0003;
    margin: 0 20px;
}
.productCart .productSummary h6 {
        font-size: 12px;
        font-weight: 500;
        font-family: Poppins-Regular;
        color: #989898;
}
.productCart  .Total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px 55px; */
    padding: 10px 18px;
}
.productCart .Total p {
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    font-family: Poppins-SemiBold;
}
.productCart .proceed a.btn {
    box-shadow: none;
    /* background: #D60012; */
    font-size: 17px;
    padding: 10px 40px;
    color: #000;
    font-weight: 500;
    margin-bottom: 20px;
    border-radius: 0px;
    position: relative;
    border: 1px solid #181818;
    border-radius: 28px;
    /* z-index: 2; */
}

.productCart .proceed a.btn:before {
    content: '';
    background: transparent;
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.6s;
    z-index:-1;
}
.productCart .proceed a.btn:hover:before {
    left: 12px;
    top: 12px;
}


.productCart .proceed h6 {
    font-size: 12px;
}

.productCart .imgBox{
    width: 65px;
    height: 65px;
    border-radius: 50%;
    overflow: hidden;
}

/* Table Css End Here */
@media (max-width: 991px) {
    .productCart .products .productDetail .product-detail .img-box {
        min-width: 80px;
    }
    .productCart .discountCode {
        display: block;
        margin-bottom: 20px;
    }
    .productCart  .Total {
        padding: 10px 10px;
    }
    .productCart .button-group {
        margin-top: 30px;
        text-align: center;
        margin-bottom: 10px;
    }
    .productCart .proceed {
        text-align: center;
    }
}
@media (max-width: 768px) {
    .productCart .quantity-inner{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .productCart .heading {
        padding-bottom: 30px;
    }
    .productCart .productSummary h6 {
        text-align: left;
    }
    .productCart .productSummary {
        margin: 0 0px;
    }
    .productCart .products .productDetail .product-detail .img-box {
        min-width: 0;
    }
    .productCart .products .productDetail .product-detail  .name {
        display: none;
    }
    .productCart table tr.topbar .productDetail {
        text-align: left;
    }
    .productCart .tableDv th.productDetail {
        width: 30%;
    }
    .productCart .tableDv th.productDetail {
        width: 25%;
    }
    .productCart table tr.topbar th {
        font-size: 11px;
        text-align: center;
    }
    .productCart .tableDv td {
        text-align: center;
    }
    .productCart .tableDv .qty input {
        width: 50px;
        padding: 0 0;
    }
    .productCart .tableDv .addcart .ActionBtn .cartBtn p {
        font-size: 13px;
    }
    .productCart .tableDv .addcart .ActionBtn {
        justify-content: space-evenly;
    }
}
@media (max-width: 480px) {
    .productCart .tableDv .qty input {
        width: 50px;
        padding: 0;
        text-align: center;
        background: transparent;
    }
    .productCart table tr.topbar th {
        font-size: 11px;
        text-align: center;
    }
    .productCart .tableDv .addcart .ActionBtn .cartBtn p {
        font-size: 12px;
    }
    .productCart .proceed a.btn {
        width: 100%;
    }
    .productCart .button-group a {
        width: 100%;
    }
    .productCart .proceed a.btn {
        width: 100%;
    }
    .productCart .proceed {
        margin: 0;
    }
}

@media (max-width: 425px) {
    .productCart .discountCode .applyCoupon input {
        background: #0000000a;
        color: #A8A8A8;
    }
    .productCart .button-group a.btn {
        margin-bottom: 20px;
    }
}