/* Footer  */
.footer{
   background-image: url('../images//footerBg.png');
}

.footer .quick-link {
    display: flex;
}
.footer-copyright{
    background-color: black;
    color: aliceblue;
}
.footer .first-col {
    color: #FFFFFF;
    font-size: 13px;
    font-family: Roboto-Regular;
    margin-top: 26px;
}
.copyright{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.footer .inner-container{
    padding: 50px 60px 0px 60px;
}

.footer .widgets2 ul li {
   list-style: none;
   margin-top: 6px;
}
.footer .widgets2 ul li a {
  color: #FFFFFF;
  text-decoration: none;
 }
 .footer .widgets2 ul li a:hover {
    color: #FFFFFF;
    text-decoration: underline;
   }

.footer .blog-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.footer .footer-profile {
    height: 65px;
    width: 65px;
    border-radius: 8px;
    /* background-color: aliceblue; */
    margin-right: 18px;
    overflow: hidden;
}

.footer .blog-container .title{
    color: #FFFFFF;
    font-size: 11px;
    font-family: Roboto-Bold;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
}
.footer .blog-container .date{
    color: #FFFFFF;
    font-size: 9px;
    font-family: Roboto-Bold;
    margin: 0px;
}

.footer .widgets2 .mainText{
    text-align: initial;
    font-size: 18px;
    font-family: Poppins-SemiBold;
    /* text-decoration: underline; */
    color: #ffff;
    text-align: center;
    /* margin-left: 33px; */
}
.footer .widgets2 .mainEmail{
    font-size: 18px;
    font-family: Poppins-SemiBold;
    /* text-decoration: underline; */
    color: #ffff;
    /* margin-left: 33px; */
    text-align: center;
}


.footer .widgets2 ul {
    text-align: initial;
    font-size: 15px;
    font-family: Roboto-Regular;
    color: #ffff;
}

.footer .blog {
    margin-left: 50px;
}

.footer .border-right {
    border-right: 1px solid #2525253d !important;
}

.footer .copy-right-text {
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-family: 'RobotoSlab-Regular';
}

.footer .hr-line{
    color: #fff;
    margin-bottom: 28px;
}

.footer .social-icons{
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer .social-color{
    color: #fff;
    padding: 14px;
}
/* Footer end  */
